<template>
  <section>
    <RegistrationGuard
      @removeCodeFromDb="removeCodeFromDb"
      @onPasswordValidated="validatePassword"
      v-if="!isUnlocked"
      :isGuiderRegistration="false"
    />

    <section v-else>
      <section v-if="vendor.id" class="form-group">
        <img class="logo mb-6 mt-2" src="../assets/logo_new.svg" />
        <h1 class="is-blue page-title">Sign up</h1>

        <form class="box mt-6">
          <div v-if="validationErrors.length" class="notification is-danger">
            <button @click="resetError()" class="delete"></button>
            <div class="content">
              Please resolve the following error(s) before proceeding.
              <ul style="margin-top: 0.3em; margin-left: 1em">
                <li
                  v-for="(error, index) in validationErrors"
                  :key="`error-${index}`"
                  v-html="error"
                />
              </ul>
            </div>
          </div>

          <b-field label="Name">
            <b-input
              icon="user"
              icon-pack="fas"
              v-model="vendor.name"
              disabled
            />
          </b-field>

          <b-field label="Email">
            <b-input
              icon="at"
              icon-pack="fas"
              v-model="vendor.email"
              disabled
              autocomplete="email"
              type="email"
            />
          </b-field>

          <b-field label="Password">
            <b-input
              icon="lock"
              icon-pack="fas"
              v-model="password"
              autocomplete="current-password"
              type="password"
              password-reveal
            >
            </b-input>
          </b-field>

          <b-button
            :loading="isLoading"
            @click.prevent="validate()"
            class="mb-3 mt-5"
            type="is-primary"
            expanded
          >
            Sign up
          </b-button>
        </form>
        <p class="is-light-purple is-size-9 is-flex is-justify-content-center">
          Already have an account?
          <router-link class="is-blue pl-1" to="/login">Login</router-link>
        </p>
        <p class="is-light-purple is-size-8 mt-6 text-align--center">
          By signing up you accept our
          <br />
          <a
            href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FContractPartners.pdf?alt=media&token=285f172b-95d5-4ee5-b04b-e8264c32cf5a"
            target="_blank"
            >Terms of Service</a
          >
          and
          <a
            href="https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/documents%2FPrivacy%20policy.pdf?alt=media&token=e6925517-73ae-4405-bc01-feaeb8e1526d"
            target="_blank"
            >Privacy Policy</a
          >
        </p>
      </section>

      <section v-else>This vendor doesn't exist</section>
    </section>
  </section>
</template>

<script>
import { db } from "../firebase";
import { mapActions, mapState, mapMutations } from "vuex";
import { UserActions, UsersStates, UsersMutations } from "../store/Storetypes";
import RegistrationGuard from "../Guiders/RegistrationGuard.vue";
export default {
  components: {
    RegistrationGuard,
  },
  data() {
    return {
      password: null,
      validationErrors: [],
      isUnlocked: false,
      code: null,
      vendor: [],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.users[UsersStates.USER],
      isLoading: (state) => state.users[UsersStates.IS_LOADING],
    }),
    vendorId() {
      return this.$route.params.vendorId;
    },
  },
  mounted() {
    this.getVendorData();
  },
  watch: {
    user: {
      handler: function () {
        if (!this.isUnlocked) return;
        if (this.user.id)
          return this.$router.push({ name: "vendor-dashboard" });
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.setIsLoading(false);
  },
  methods: {
    ...mapActions({
      signUp: UserActions.SIGN_UP_VENDOR,
      googleSignUp: UserActions.SIGN_IN_WITH_GOOGLE,
    }),
    ...mapMutations({
      setIsLoading: UsersMutations.SET_IS_LOADING,
    }),
    removeCodeFromDb(code) {
      this.code = code;
    },
    async getVendorData() {
      const ref = db.firestore().collection("vendors").doc(this.vendorId);
      const doc = await ref.get();

      if (doc.exists) {
        this.vendor = doc.data();
      }
    },
    resetError() {
      this.validationErrors = [];
    },
    validatePassword(isPasswordValid) {
      if (!isPasswordValid) return;
      this.isUnlocked = isPasswordValid;
    },
    validate() {
      // Clear the errors before we validate again
      this.resetError();

      // password validation
      if (!this.password) {
        this.validationErrors.push("<strong>Password</strong> cannot be empty");
      }

      if (/.{6,}/.test(this.password) != true) {
        this.validationErrors.push(
          "<strong>Password</strong> must be at least 6 characters long"
        );
      }

      // when valid -> sign up
      if (this.validationErrors.length <= 0) {
        this.signUpUser();
      }
    },
    googleSignup() {
      this.googleSignUp(this.isGuideRegistration);
    },
    async signUpUser() {
      const userData = {
        email: this.vendor.email,
        id: this.vendor.id,
        password: this.password,
        displayName: this.vendor.name,
      };

      await this.signUp(userData);

      db.firestore()
        .collection("codes")
        .doc(this.code)
        .delete()
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.hr-text {
  overflow: visible;
  /* For IE */
  padding: 0;
  border: none;
  border-top: medium #927bd0;
  color: #a29ebb;
  text-align: center;

  &:after {
    content: "Or";
    font-family: "Inter", sans-serif;
    display: inline-block;
    position: relative;
    top: -1em;
    font-size: 0.75em;
    padding: 0 1.75em;
    background: white;
  }
}

hr {
  background-color: #edebf2;
}

.button.is-primary.is-outlined {
  border: 2px solid;
  width: 209px;
  border-radius: 6px;
  font-weight: 500;
}

.checkbox-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
}

.b-radio.radio:not(.button) {
  color: #0e3d4d;
  font-size: 0.9rem;
}

.logo {
  height: 32px;
  width: auto;
}

.notification {
  border: none;
}

.box {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

.form-group {
  margin-left: 25%;
  margin-right: 25%;
}

@media only screen and (max-width: 768px) {
  .box {
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
  }

  #app {
    width: 100%;
  }

  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
